import device from '~/mixins/device.js'

export default {
  mixins: [device],

  props: {
    name: {
      type: String,
    },

    description: {
      type: String,
    },

    category: {
      type: String,
    },

    image: {
      type: Object,
    },

    video: {
      type: Object,
    },

    imageMobile: {
      type: Object,
    },
  },

  computed: {
    computedVideo() {
      return {
        ...this.video,
        image: this.imageMobile,
      }
    },
  },
}
